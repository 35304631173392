define('core/models/purchase-order', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    poType: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    backorderPolicy: _emberData.default.attr('number'),
    company: _emberData.default.attr('string'),
    shipTo: _emberData.default.attr('string'),
    assign: _emberData.default.attr('string'),
    confirmationNumber: _emberData.default.attr('string'),
    terms: _emberData.default.attr('string'),
    remarks: _emberData.default.attr('string'),
    details: _emberData.default.attr('', { readOnly: true }),
    shipping: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),
    ecTotals: computed.mapBy('purchaseOrderItems', 'extPrice'),
    ecTotal: computed.sum('ecTotals'),
    placedAt: _emberData.default.attr('date'),
    comments: _emberData.default.attr('string'),
    receivedAt: _emberData.default.attr('date'),
    requiredAt: _emberData.default.attr('date'),
    storeId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    destinationsAssignedAt: _emberData.default.attr('date'),
    stationId: _emberData.default.attr('number'),
    confirmedAt: _emberData.default.attr('date'),
    deliveredAt: _emberData.default.attr('date'),
    plannedAt: _emberData.default.attr('date'),
    shipmentIds: _emberData.default.attr('string'),
    dropShip: _emberData.default.attr('boolean'),
    purchaseOrderItems: _emberData.default.hasMany('purchase-order-item'),
    // items: DS.hasMany('item'),
    amazonListings: _emberData.default.hasMany('amazon-listing'),
    supplier: _emberData.default.belongsTo('supplier'),
    poiDestinations: computed.mapBy('purchaseOrderItems', 'destinations'),
    pdf: (0, _emberApiActions.memberAction)({ path: 'pdf', type: 'get' }),
    previewDestinations: (0, _emberApiActions.memberAction)({ path: 'preview_destinations', type: 'put' }),
    clearPreview: (0, _emberApiActions.memberAction)({ path: 'clear_preview', type: 'put' }),
    assignDestinations: (0, _emberApiActions.memberAction)({ path: 'assign_destinations', type: 'put' }),
    assignShipments: (0, _emberApiActions.memberAction)({ path: 'assign_shipments', type: 'put' }),
    previewShipments: (0, _emberApiActions.memberAction)({ path: 'preview_shipments', type: 'put' }),
    shipmentDocs: (0, _emberApiActions.memberAction)({ path: 'shipment_docs', type: 'put' }),
    receive: (0, _emberApiActions.memberAction)({ path: 'receive', type: 'put' }),
    place: (0, _emberApiActions.memberAction)({ path: 'place', type: 'put' }),
    printLabels: (0, _emberApiActions.memberAction)({ path: 'print_labels', type: 'put' }),
    cancelShipment: (0, _emberApiActions.memberAction)({ path: 'cancel_shipment', type: 'put' }),
    destinations: computed('poiDestinations', function () {
      var dests = [];
      this.get('poiDestinations').forEach(function (list) {
        list.forEach(function (dest) {
          // if (dest.get('scannable')) {
          dests.push(dest);
          // }
        });
      });
      return dests;
    }),
    changed: computed('hasDirtyAttributes', 'purchaseOrderItems.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('purchaseOrderItems').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    buttonText: computed('changed', function () {
      if (this.get('changed')) {
        return 'Save Purchase Order';
      } else {
        return 'Purchase Order Saved';
      }
    })

  });
});